<template>
  <button
    class="px-5 py-2 text-white uppercase rounded-lg cursor-pointer bg-gradient-to-b from-red-900 to-red-800 hover:from-red-800 hover:to-red-700 active:from-red-800 active:to-red-700"
  >
    <slot></slot>
  </button>
</template>

<style scoped>
/* button {
  font: inherit;
  padding: 0.5rem 2rem;
  cursor: pointer;
  text-transform: uppercase;
} */
</style>