<template>
  <div class="search-tool">
    <form class="text-left" @submit.prevent="submitForm">
      <label class="block">
        <span class="block mb-1 text-sm text-left text-gray-100"
          >Favorite Band:</span
        >
        <input
          type="text"
          name="searchterm"
          class="px-4 py-2 mb-2 border border-gray-300 form-input"
          v-model="state.searchterm"
        />
        <button id="search"><i class="fas fa-search"></i></button>
      </label>
    </form>
    <the-spinner v-if="state.loadingState" />
  </div>
  <div
    class="flex items-center justify-between pb-6 togglewrap"
    v-if="state.albums.length > 0"
    @click="setToggleMusician"
  >
    <h2 :class="{ 'text-yellow-400': !state.toggleMusician }">Band View</h2>
    <div
      class="flex items-center p-1 duration-300 ease-in-out bg-gray-300 rounded-full w-14 h-7"
    >
      <div
        class="w-6 h-6 duration-300 ease-in-out transform bg-gray-500 rounded-full shadow-md"
        :class="{ 'translate-x-6': state.toggleMusician }"
      ></div>
    </div>
    <h2 :class="{ 'text-yellow-400': state.toggleMusician }">Musician View</h2>
  </div>
  <div class="selectWrapper" v-if="state.selectedMusician !== ''">
    <label for="selectMusician">
      <select
        id="musician"
        name="musician"
        @change="setMusician(state.selectedMusician)"
        v-model.trim="state.selectedMusician"
        class="block w-full mt-1 mb-4 text-white bg-gray-600 bg-opacity-25 border-gray-600 focus:border-gray-600 focus:ring-transparent form-select"
      >
        <option
          class="bg-gray-600"
          v-for="member in state.members"
          :value="member.name"
          :key="member.id"
        >
          {{ member.name }}
        </option>
      </select>
    </label>
  </div>
  <div
    class="text-gray-300 site-info"
    v-if="state.albums.length === 0 && state.artists.length === 0"
  >
    <div class="mb-6 text-lg">
      <base-card>
        <div class="flex flex-row quote-wrap">
          <i class="fas fa-lightbulb"></i>
          <div>
            "The key to mastering any subject is to understand the importance of
            its individual parts."
          </div>
        </div>
      </base-card>
    </div>
    <div class="mb-48 text-left text-md">
      <base-card>
        <div class="flex flex-col quote-wrap">
          <p class="mb-6">
            Our simple search form gathers up every musician that is in or has
            been in the band you request.
          </p>
          <p class="mb-6">
            We then track down every album that each of these musicians have
            been a part of recording.
          </p>
          <p>
            Clicking the play button will allow you to listen to the album in
            either Spotify, if available, or YouTube.
          </p>
        </div>
      </base-card>
    </div>
  </div>
  <div class="container pb-6 album-list-wrap">
    <!-- Band Cards -->
    <div class="flex flex-wrap justify-center gap-3">
      <div
        class="flex flex-col text-sm card"
        v-for="band in state.artists"
        :key="band.id"
      >
        <band-card :band="band" :setBand="setBand" />
      </div>
    </div>
    <!-- Album Cards -->
    <!-- v-if="state.selectedMusician !== ''" -->
    <div
      class="grid grid-cols-2 gap-x-0 gap-y-4 sm:gap-5 ml-3 sm:ml-8 lg;ml-0 album-list sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5"
    >
      <div
        class="flex flex-col text-sm card"
        v-for="album in filteredAlbums"
        :key="album.id"
      >
        <album-card
          :album="album"
          :setMusician="setMusician"
          :selectedMusician="state.selectedMusician"
          :updateActiveAlbum="global.updateActiveAlbum"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, inject } from "vue";
import axios from "axios";
import AlbumCard from "./AlbumCard.vue";
import BandCard from "./BandCard.vue";
import TheSpinner from "../components/UI/TheSpinner";
export default {
  components: { AlbumCard, BandCard },
  setup() {
    const global = inject("global");
    const state = reactive({
      searchterm: "",
      artists: [],
      bandMembers: [],
      albums: [],
      filteredAlbums: [],
      members: [],
      memberLinks: {},
      loadingState: false,
      toggleMusician: true,
      selectedMusician: "",
      lastMusician: "",
    });
    const filteredAlbums = computed(() => {
      const uniqueAlbums = [];
      const titles = [];
      state.albums.map((album) => {
        if (titles.indexOf(album.title) === -1) {
          titles.push(album.title);
          uniqueAlbums.push(album);
        }
      });
      return uniqueAlbums;
    });
    const getMemberAlbums = async (member) => {
      const result2 = await axios
        .get(
          `https://us-central1-findnewtunes.cloudfunctions.net/app/api/getBandMemberReleases/${member.id}`
        )
        .catch((error) => {
          console.log("Error searching for first artist's releases.", error);
        });
      if (result2?.data && result2.data?.releases) {
        state.artists = [];
        state.albums = result2.data?.releases;
        if (member.name !== state.lastMusician) {
          setMusician(member.name);
        }
      }
    };
    const getAllMembersAlbums = async () => {
      const result3 = await axios
        .post(
          `https://us-central1-findnewtunes.cloudfunctions.net/app/api/getAllArtistMemberReleases`,
          state.members
        )
        .catch((error) => {
          console.log("Error searching for first artist's releases.", error);
        });
      if (result3?.data && result3.data?.releases) {
        state.artists = [];
        state.albums = result3.data?.releases;
        setMusician("");
      }
    };
    const setBand = async (bandId) => {
      const result = await axios
        .get(
          `https://us-central1-findnewtunes.cloudfunctions.net/app/api/getBandMembers/${bandId}/${state.artists[0].title}`
        )
        .catch((error) => {
          console.log("Error searching for band", error);
        });
      if (result?.data && result.data?.memberData) {
        state.members = result?.data?.memberData;
        state.loadingState = true;
        await getMemberAlbums(state.members[0]);
        state.loadingState = false;
      } else {
        // No member data available - need new API endpoint for band albums
        console.log("An error has occurred.");
      }
    };
    const submitForm = async () => {
      state.loadingState = true;
      clearToggle();
      state.albums = [];
      const result = await axios
        .get(
          `https://us-central1-findnewtunes.cloudfunctions.net/app/api/searchArtist/${state.searchterm}`
        )
        .catch((error) => {
          console.log("Error searching for band", error);
        });
      if (result?.data?.results) {
        state.artists = result.data.results;
      } else {
        state.artists = [];
      }
      state.loadingState = false;
      if (state.artists.length === 1) {
        setBand(state.artists[0].id);
      }
      // console.log(`Search for ${state.searchterm} results:`, state.artists);
    };
    const clearToggle = () => {
      state.toggleMusician = true;
      setMusician("");
    };
    const setMusician = async (musician) => {
      state.selectedMusician = musician;
      if (musician !== "") {
        const memberObject = state.members.find(
          (item) => item.name === musician
        );
        if (musician !== state.lastMusician) {
          state.loadingState = true;
          await getMemberAlbums(memberObject);
          state.loadingState = false;
        }
        // console.log("setMusician memberObject:", memberObject);
        state.lastMusician = musician;
      }
      if (musician !== "" && !state.toggleMusician) {
        setToggleMusician();
      }
    };
    const setToggleMusician = async () => {
      state.toggleMusician = !state.toggleMusician;
      if (state.selectedMusician === "") {
        if (
          state.lastMusician != "" &&
          state.members.indexOf(state.lastMusician) > -1
        ) {
          state.selectedMusician = state.lastMusician;
        } else {
          if (state.lastMusician !== "") {
            const memberObject = state.members.find(
              (item) => item.name === state.lastMusician
            );
            state.loadingState = true;
            await getMemberAlbums(memberObject);
            state.loadingState = false;
            state.selectedMusician = state.lastMusician;
          } else {
            state.selectedMusician = state.members[0].name;
          }
        }
      }
      if (!state.toggleMusician) {
        setMusician("");
        state.loadingState = true;
        await getAllMembersAlbums();
        state.loadingState = false;
      }
    };
    return {
      global,
      state,
      submitForm,
      TheSpinner,
      setMusician,
      filteredAlbums,
      setToggleMusician,
      clearToggle,
      setBand,
    };
  },
};
</script>

<style>
.togglewrap,
.selectWrapper {
  width: 320px;
  margin: 0 auto;
  cursor: pointer;
}
#musician:focus {
  outline: 0;
}
.site-info {
  width: 320px;
  margin: 0 auto;
}
.fa-lightbulb {
  font-size: 100px;
}
.search-tool {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.search-tool input {
  background-color: #eeeeee;
  border: none;
  border-radius: 3px;
  font-family: inherit;
  padding: 0.5rem 1rem;
  width: 200px;
  outline: none;
  font-size: 14px;
}
.search-tool form {
  width: 250px;
  margin: 1rem auto 1rem auto;
}
.search-tool button {
  background-color: transparent;
  border: none;
  /* color: #7a7633; */
  font-size: 1.6rem;
  margin-left: 10px;
  cursor: pointer;
  outline: none;
}
.card {
  width: 166px;
}
.card img {
  /* width: 120px; */
}
@media only screen and (max-width: 359px) {
  .album-list {
    display: block;
    width: 184px;
    margin: 0 auto;
  }
  .card {
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 420px) and (max-width: 639px) {
  .album-list-wrap {
    width: 410px;
  }
}
</style>
