<template>
  <base-card>
    <div class="album-wrapper">
      <div class="albumcover" @click="playAlbum">
        <img :src="album.thumb" class="coverart" loading="lazy" />
        <img src="/play-btn.png" class="playbtn" />
      </div>
      <div class="mt-1 font-bold text-yellow-400 nowrap">
        {{ album.artist }}
      </div>
      <div class="mt-0 text-xs text-gray-300 nowrap" v-if="album.year">
        {{ album.year }} - {{ album.title }}
      </div>
      <div class="mt-0 text-xs text-gray-300 nowrap" v-else>
        {{ album.title }}
      </div>
      <div class="text-xs text-gray-400" v-if="album.musician">
        <div class="mt-2 font-bold">Featured Artists:</div>
        <div class="musician-wrapper">
          <div
            v-for="member in album.musician"
            :key="member"
            @click="setMusician(member)"
            :class="{ 'text-yellow-400': member === selectedMusician }"
          >
            {{ member }}
          </div>
        </div>
      </div>
      <div class="flex mt-3 justify-evenly play-wrap" :id="`wrap_${album.id}`">
        <a
          href=""
          target="_blank"
          :id="`spotify_${album.id}`"
          class="text-green-600"
          @click="hideButtons"
          ><i class="fab fa-spotify fa-2x"></i
        ></a>
        <a
          href=""
          target="_blank"
          :id="`youtube_${album.id}`"
          class="text-red-700"
          @click="hideButtons"
          ><i class="fab fa-youtube fa-2x"></i
        ></a>
      </div>
    </div>
  </base-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["album", "setMusician", "selectedMusician", "updateActiveAlbum"],
  setup(props) {
    const playAlbum = async () => {
      const ytAlbumLink = `https://www.youtube.com/results?search_query=${props.album.artist}+${props.album.title}`;
      const yt = document.getElementById(`youtube_${props.album.id}`);
      yt.href = ytAlbumLink;
      const result = await axios
        .get(
          `https://us-central1-dci-digital-team.cloudfunctions.net/app/api/getSpotifyLink/${props.album.artist}/${props.album.title}`
        )
        .catch((error) => {
          console.log("Error searching for band", error);
        });
      if (result?.data?.link?.length > 0) {
        const spotAlbumLink = result.data.link;
        const spot = document.getElementById(`spotify_${props.album.id}`);
        spot.href = spotAlbumLink;
        const spotifyChunks = spotAlbumLink.split("/");
        const spotifyAlbumId = spotifyChunks[spotifyChunks.length - 1];
        const result2 = await axios
          .get(
            `https://us-central1-dci-digital-team.cloudfunctions.net/app/api/getSpotifyPreviews/${spotifyAlbumId}`
          )
          .catch((error) => {
            console.log("Error retrieving tracks", error);
          });
        let trackList = [];
        result2?.data?.links
          ? (trackList = result2.data.links)
          : console.log("No tracks available");
        const albumObj = {
          artist: props.album.artist,
          albumName: props.album.title,
          tracks: trackList,
        };
        props.updateActiveAlbum(albumObj);
      } else {
        const spot = document.getElementById(`spotify_${props.album.id}`);
        spot.style.display = "none";
      }
      document.getElementById(`wrap_${props.album.id}`).style.bottom = 0;
    };
    const hideButtons = () => {
      document.getElementById(`wrap_${props.album.id}`).style.bottom = "-40px";
    };
    return {
      playAlbum,
      hideButtons,
    };
  },
};
</script>

<style>
.album-wrapper {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  border: 0px solid red;
  padding-bottom: 40px;
  overflow: hidden;
}
.album-wrapper .nowrap {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.albumcover {
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.albumcover .coverart {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-height: 100%;
}
.playbtn {
  max-width: 60%;
  opacity: 0.7;
}
.play-wrap {
  width: 100%;
  border: 0px solid white;
  position: absolute;
  bottom: -40px;
}
.musician-wrapper {
  cursor: pointer;
}
</style>
