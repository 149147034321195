<template>
  <base-card>
    <div class="band-wrapper">
      <div
        class="albumcover"
        :style="`background-image: url('${band.thumb}')`"
        @click="setBand(band.id)"
      ></div>
      <div class="mt-1 font-bold text-yellow-400 nowrap">{{ band.title }}</div>
    </div>
  </base-card>
</template>

<script>
export default {
  props: ["band", "setBand"],
};
</script>

<style>
.band-wrapper {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  border: 0px solid red;
  padding-bottom: 15px;
  overflow: hidden;
}
.band-wrapper .nowrap {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.albumcover {
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.playbtn {
  max-width: 60%;
  opacity: 0.7;
}
.play-wrap {
  width: 100%;
  border: 0px solid white;
  position: absolute;
  bottom: -40px;
}
.musician-wrapper {
  cursor: pointer;
}
</style>