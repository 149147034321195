import { createApp } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import "./assets/style.css"
import App from "./App.vue"
import BaseCard from "./components/UI/BaseCard.vue"
import BaseButton from "./components/UI/BaseButton.vue"
import HomePage from "./components/HomePage.vue"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: HomePage },
    { path: "/:notFound(.*)", redirect: "/" },
  ],
})

const app = createApp(App)

app.component("base-card", BaseCard)
app.component("base-button", BaseButton)

app.use(router)

app.mount("#app")
