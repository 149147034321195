<template>
  <div class="bg-gradient-to-b from-indigo-900 to-indigo-800">
    <nav
      class="container items-center justify-between w-full px-4 py-5 text-xl font-bold text-gray-300 sm:flex"
    >
      <router-link to="/" class="mr-8">{{ global.state.siteName }}</router-link>
      <div
        class="flex-grow mt-1 font-light text-left sm:mt-0"
        v-show="global.state.activeAlbum.artist"
      >
        <span class="text-sm"
          ><span class="font-bold text-yellow-400">{{
            global.state.activeAlbum?.artist
          }}</span
          ><span class="hidden sm:inline">
            : {{ global.state.activeAlbum?.albumName }}</span
          >
          -
        </span>
        <span class="text-sm font-light">
          {{ global.state.activeAlbum.tracks[global.state.trackNum]?.name }}
        </span>
        <div
          class="flex flex-col justify-center h-4 p-1 border border-gray-300"
        >
          <div id="progressbar" class="w-0 h-full bg-gray-300"></div>
        </div>
        <audio
          :src="global.state.activeAlbum.tracks[global.state.trackNum]?.url"
          id="player"
        ></audio>
      </div>
      <section
        id="controls"
        class="flex justify-center mt-3 sm:mt-0"
        v-show="global.state.activeAlbum?.artist.length"
      >
        <button id="prev" class="ml-4" @click="changeTrack('decrement')">
          <i class="fas fa-backward"></i>
        </button>
        <button id="playBtn" class="ml-4">
          <i class="text-3xl fas fa-play"></i>
        </button>
        <button id="next" class="ml-4" @click="changeTrack('increment')">
          <i class="fas fa-forward"></i>
        </button>
        <button id="volumeIcon" class="ml-4 cursor-default">
          <i class="text-2xl fas fa-volume-up"></i>
        </button>
        <input
          class="w-16 h-2 mt-4 ml-2 overflow-visible bg-gray-300 rounded-lg appearance-none"
          type="range"
          min="1"
          max="100"
          step="1"
          v-model="state.volumeSlider"
        />
      </section>
    </nav>
  </div>
</template>

<script>
import { onMounted, inject, reactive, watch } from "vue";
export default {
  setup() {
    const global = inject("global");
    const state = reactive({ volumeSlider: 100 });
    watch(
      () => state.volumeSlider,
      (newVal) => {
        const newVolume = newVal / 100;
        document.querySelector("#player").volume = newVolume;
      }
    );
    const playPreview = () => {
      const playBtn = document.querySelector("#playBtn");
      const controls = document.querySelector("#controls");
      playBtn.querySelector("i.fas").classList.remove("fa-play");
      playBtn.querySelector("i.fas").classList.add("fa-pause");
      controls.classList.add("play");
      document.querySelector("#player").play();
    };
    const pausePreview = () => {
      const playBtn = document.querySelector("#playBtn");
      const controls = document.querySelector("#controls");
      playBtn.querySelector("i.fas").classList.add("fa-play");
      playBtn.querySelector("i.fas").classList.remove("fa-pause");
      controls.classList.remove("play");
      document.querySelector("#player").pause();
    };

    const changeTrack = (direction = "increment") => {
      if (direction === "decrement") {
        global.decrementTrack();
      } else {
        global.incrementTrack();
      }
      setTimeout(() => {
        playPreview();
      }, 250);
    };

    const updateProgress = (e) => {
      const progressbar = document.querySelector("#progressbar");
      const { duration, currentTime } = e.srcElement;
      const progressPercent = (currentTime / duration) * 100;
      progressbar.style.width = `${progressPercent}%`;
    };

    const init = () => {
      const playBtn = document.querySelector("#playBtn");
      const controls = document.querySelector("#controls");
      const player = document.querySelector("#player");
      playBtn.addEventListener("click", () => {
        const isPlaying = controls.classList.contains("play");
        if (isPlaying) {
          pausePreview();
        } else {
          playPreview();
        }
      });
      player.addEventListener("ended", changeTrack);
      player.addEventListener("timeupdate", updateProgress);
    };
    onMounted(() => {
      init();
    });

    return {
      global,
      playPreview,
      pausePreview,
      changeTrack,
      init,
      state,
    };
  },
};
</script>

<style>
#controls button:focus,
input[type="range"]:focus {
  outline: 0;
}
#volumeIcon {
  width: 27px;
  text-align: center;
}
input[type="range"]::-webkit-slider-thumb {
  width: 15px;
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  cursor: pointer;
  background: #fabf25;
  border-radius: 50%;
}
</style>