import { reactive, readonly } from "vue"

const state = reactive({
  siteName: "FindNewTunes.com",
  trackNum: 0,
  volume: 1.0,
  activeAlbum: {
    artist: "",
    albumName: "",
    tracks: [],
  },
})

const updateActiveAlbum = (album) => {
  state.trackNum = 0
  state.activeAlbum = album
  const playBtn = document.querySelector("#playBtn")
  const controls = document.querySelector("#controls")
  playBtn.querySelector("i.fas").classList.remove("fa-play")
  playBtn.querySelector("i.fas").classList.add("fa-pause")
  controls.classList.add("play")
  setTimeout(() => {
    document.querySelector("#player").play()
  }, 250)
}

const togglePrefs = () => {
  state.showPrefs = !state.showPrefs
}

const underScored = function underScored(input) {
  return input.replace(/ /g, "_")
}

const incrementTrack = function() {
  state.trackNum < state.activeAlbum.tracks.length - 1
    ? (state.trackNum += 1)
    : (state.trackNum = 0)
}

const decrementTrack = function() {
  state.trackNum > 0
    ? (state.trackNum -= 1)
    : (state.trackNum = state.activeAlbum.tracks.length - 1)
}

export default {
  state: readonly(state),
  togglePrefs,
  underScored,
  incrementTrack,
  decrementTrack,
  updateActiveAlbum,
}
