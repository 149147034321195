<template>
  <div class="bg-gradient-to-b from-indigo-900 to-indigo-800">
    <div
      class="container flex justify-center w-full px-4 py-5 text-base font-normal text-gray-300"
    >
      &copy;{{ state.currYear }} {{ global.state.siteName }}
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, computed } from "vue";
export default {
  inject: ["global"],
  setup() {
    const state = reactive({
      currYear: "2020",
    });
    onMounted(async () => {
      state.currYear = new Date().getFullYear();
    });
    const sortedAlbums = computed(() => {
      return [];
    });
    return {
      state,
      sortedAlbums,
    };
  },
};
</script>

<style>
</style>