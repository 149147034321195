<template>
  <div class="flex flex-col h-screen">
    <header-item class="header" />
    <div class="flex-1 flex-grow overflow-y-auto">
      <div class="container flex-grow antialiased text-gray-600 main">
        <router-view></router-view>
      </div>
    </div>
    <footer-item class="footer" />
  </div>
</template>

<script>
import global from "./global.js";
import HeaderItem from "./components/HeaderItem.vue";
import FooterItem from "./components/FooterItem.vue";
export default {
  provide: { global },
  components: { HeaderItem, FooterItem },
};
</script>

<style>
</style>
